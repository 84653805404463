@use 'node_modules/@angular/material' as mat;
@import 'node_modules/@angular/material/theming';
@import url('https://fonts.googleapis.com/css?family=EB+Garamond:600,700|Titillium+Web:300,400,600,700|Material+Icons');
@import 'src/variables';
@import 'src/theme';

$custom-typography: mat.define-typography-config($font-family: '"Titillium Web", sans-serif;',
$body-1: mat.define-typography-level(18px, 27px, 500),
$display-2: mat.define-typography-level(64px, 76.8px, 600),
$subheading-2: mat.define-typography-level(26px, 31.2px, 600));

@include mat.core($custom-typography);

html {
  height: 100%;
  margin: 0;
  width: 100%;
}

body {
  font-family: 'Titillium Web', sans-serif;
  height: 100%;
  margin: 0 auto;
}

/* General styling that applies to many pages */

/* Plain is just a holder for content on pages w/o hero images.*/
.spb-plain-content {
  display: flex;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
  margin-top: 8px;
  max-width: $spb-max-width;
  width: 100%;
}

@media screen and (min-width: 400px) {
  .spb-plain-content {
    // border: 1px solid red;
    // width: 95%;
  }

  .spb-plain-content > * {
    padding-left: 45px;
    padding-right: 45px;
  }
}

@media screen and (max-width: $spb-max-width) and (min-width: $spb-small) {
  .spb-plain-content {
    // border: 3px solid dodgerblue;
    // width: 95%;
  }

  .spb-plain-content > * {
    padding-left: 45px;
    padding-right: 45px;
  }
}

@media screen and (max-width: 1215px) and (min-width: 400px) {
  .spb-plain-content {
    // border: 3px solid dodgerblue;
    // width: 95%;
  }

  .spb-plain-content > * {
    padding-left: 65px;
    padding-right: 45px;
  }
}


@media screen and (max-width: 400px) {
  .spb-plain-content {
    // border: 5px solid lawngreen;
  }

  .spb-plain-content > * {
    padding-left: 15px;
    padding-right: 15px;
  }
}


.spb-error {
  background-color: $spb-red;
  color: #fff;
  font-weight: bold;
  margin: 1em auto;
  padding: 0.7em;
  text-align: center;
  width: 90%;
}

/* use this to grow */
.spb-filler {
  display: flex;
  flex: 1 0;
}

/* Wrap headers, footers and content in this one */
.spb-holder {
  display: flex;
  flex-direction: column;
  height: 0; // So that IE realizes we actually have a height
  min-height: 100%;
}

/**
  Checkbox labels do not line break and have to be here ... 2019-08-08 /Daniel
 */
.mat-checkbox-layout {
  white-space: normal !important;
}

.mat-radio-label {
  white-space: normal !important;
}

.mat-checkbox-inner-container {
  margin: 4px 8px auto 0 !important;
}

/* Fix the height of input fields? */
//.mat-input-element {
//  line-height: 10px !important;
//}
input {
  line-height: 19px !important;
}

h1,
h2,
h3 {
  margin-bottom: 7px !important;
  margin-top: 15px !important;
}

h4 {
  margin-bottom: 7px !important;
  margin-top: 20px !important;
}

a {
  color: #232323;
}

